import { ReactComponent as CheckIcon } from '@material-symbols/svg-400/rounded/check_circle-fill.svg';
import { ReactComponent as UnCheckIcon } from '@material-symbols/svg-400/rounded/do_not_disturb_on.svg';
import styled from 'styled-components';

export const CheckedIcon = styled(CheckIcon)`
  width: 1rem;
  height: 1rem;
  fill: var(--success-500);
`;

export const UnCheckedIcon = styled(UnCheckIcon)`
  width: 1rem;
  height: 1rem;
  fill: var(--gray-500);
`;
