import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

const METADATA_XXS_BREAKPOINT_RANGE = `@media (width <= 320px)`;
const METADATA_XS_BREAKPOINT_RANGE = `@media (width > 320px)`;
export const METADATA_M_BREAKPOINT_RANGE = `@media (width >= 1252px)`;
export const METADATA_S_BREAKPOINT_RANGE = `@media (width < 1252px)`;

// eslint-disable-next-line sort-exports/sort-exports
export const MetaData = styled.div`
  ${TEXT_STYLES.bodyCopyMedium}
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  border: 1px solid var(--gray-200);
  background: #fff;
  color: var(--gray-500);
  max-width: 100%;
  overflow: auto hidden;

  ${METADATA_XXS_BREAKPOINT_RANGE} {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  ${METADATA_XS_BREAKPOINT_RANGE} {
    display: inline-flex;
  }

  ${METADATA_S_BREAKPOINT_RANGE} {
    width: 100%;
  }
`;

export const MetaDataItem = styled.div`
  display: grid;
  grid-template-columns: 1.75rem 1fr;
  grid-template-rows: 1.25rem 1.25rem;
  gap: 0 0.5rem;

  ${METADATA_XXS_BREAKPOINT_RANGE} {
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--gray-200);
    width: 100%;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  ${METADATA_XS_BREAKPOINT_RANGE} {
    border-right: 1px solid var(--gray-200);
    padding: 0 1rem;
    flex-grow: 1;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
    }
  }

  svg {
    fill: var(--gray-600);
    width: 1.75rem;
    grid-area: 1 / 1 / 3 / 2;
    height: 2.5rem;
  }
`;

export const MetaDataItemHeader = styled.div`
  grid-area: 1 / 2 / 2 / 3;
`;

export const MetaDataItemValue = styled.div`
  grid-area: 2 / 2 / 3 / 3;
  font-weight: 500;
`;
