import { ReactComponent as SettingsIcon } from '@material-symbols/svg-400/rounded/manufacturing.svg';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import UnstyledButton from '../../components/UnstyledButton';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../constants';

export const AboutBenchmarksText = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-800);
  padding: 0 0.75rem;

  &.nonclinical {
    margin-bottom: 1rem;
  }
`;

export const BenchmarkBlock = styled(motion.div)`
  display: grid;
  min-height: 30.25rem;

  /* extra 4 pixels prevents focus border on buttons from being hidden */
  padding-bottom: 4px;
  padding-right: 4px;

  ${MOBILE_BREAKPOINT_RANGE} {
    row-gap: 1.5rem;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    row-gap: 0.75rem;
  }
`;

export const BenchmarksContainer = styled.div`
  display: grid;
  border: 1px solid var(--gray-200);
  background: #fff;
  border-radius: 1.5rem;
  padding: 0.75rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    row-gap: 1.5rem;
    margin-bottom: var(--space-4);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    row-gap: 0.75rem;
    margin-bottom: var(--space-3);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: end;
`;

export const ExpandButton = styled(UnstyledButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 3rem;
    height: 3rem;
    fill: var(--purple-400);
  }
`;

export const GearIcon = styled(SettingsIcon)`
  display: flex;
  width: 3rem;
  height: 3rem;
  justify-content: center;
  align-items: center;
  fill: var(--gray-400);
`;

export const GeneratingBenchmarksContainer = styled.div`
  display: flex;
  padding: 2rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid var(--gray-200);
  background: var(--gray-50);
`;

export const GeneratingBenchmarkText = styled.div`
  ${TEXT_STYLES.bodyCopySmall}
  color: var(--gray-600);
  text-align: center;
  max-width: 30rem;
`;

export const Link = styled.a`
  text-decoration: none;
  color: var(--purple-400);

  &.is-focused,
  &:focus {
    outline: 2px solid var(--bright-purple-400);
    border-radius: 0.25rem;
    outline-offset: 2px;
  }
`;

export const Subtitle = styled.h2`
  ${TEXT_STYLES.headlineStandardLevel2Regular}
  color: var(--purple-400);
  padding: 0 0.75rem;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
