import { useFeatureFlagEnabled } from 'posthog-js/react';
import { Outlet } from 'react-router-dom';
import SecondaryNavigation from 'src/components/SecondaryNavigation';

interface Props {
  isHidden?: boolean;
}

const Wrapper = ({ isHidden = false }: Props) => {
  const isNewProfileNavigationEnabled = useFeatureFlagEnabled('new_profile_navigation');

  if (isHidden) return <Outlet />;

  const educationLinks =
    isNewProfileNavigationEnabled === true
      ? [
          {
            children: [
              { link: '/pathways', title: 'Pathways' },
              { link: '/collections', title: 'Collections' },
              { link: '/all-education', title: 'Courses' }
            ],
            link: '/dashboard/education/pathways',
            parentLink: '/dashboard/education',
            title: 'Catalog'
          },
          {
            children: [
              { link: '/in-progress', title: 'In-progress' },
              { link: '/completions-and-certificates', title: 'Completions and certificates' }
            ],
            link: '/dashboard/my-education/in-progress',
            parentLink: '/dashboard/my-education',
            title: 'My education'
          }
        ]
      : [
          { link: '/dashboard/education/collections', title: 'Collections' },
          { link: '/dashboard/education/pathways', title: 'Pathways' },
          { link: '/dashboard/education/all-education', title: 'All education' }
        ];

  return (
    <>
      <SecondaryNavigation
        linkList={educationLinks}
        navTitle="Education"
      />
      <Outlet />
    </>
  );
};

export default Wrapper;
