import Alert from 'src/components/Alert';
import styled from 'styled-components';

import Main from '../components/Main';

export const FullWidthMain = styled(Main)`
  grid-template-columns: 1fr;
`;

export const InProgressResources = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 0;
  margin: 0;
`;

export const NoInProgressResources = styled(Alert)`
  margin: 0 auto;
  max-width: 45.375rem;
  background-color: #fff;
`;
