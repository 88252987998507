import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import SecondaryNavigation from 'src/components/SecondaryNavigation';
import ToastProvider from 'src/components/ToastProvider';
import useUser from 'src/hooks/useUser';

import useBreakpointRange from '../../../../../hooks/useBreakpointRange';

import * as S from './styles';

const Wrapper = () => {
  const { user } = useUser();
  const { isInDesktopBreakpointRange, isInMobileBreakpointRange } = useBreakpointRange();
  const isNewProfileNavigationEnabled = useFeatureFlagEnabled('new_profile_navigation');

  const location = useLocation();

  const linksRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isInDesktopBreakpointRange || isNewProfileNavigationEnabled === true) return;

    const links = linksRef.current!;

    if (location.pathname === '/dashboard/profile/information') {
      links.scrollTo({ left: 0 });
    } else if (location.pathname === '/dashboard/profile/cultural-competencies') {
      links.scrollTo({ left: 194.27 });
    } else if (location.pathname === '/dashboard/profile/certifications') {
      links.scrollTo({ left: 194.27 + 257.56 });
    } else if (location.pathname === '/dashboard/profile/work-experience') {
      links.scrollTo({ left: 194.27 + 257.56 + 129.25 });
    } else if (location.pathname === '/dashboard/profile/non-clinical-experience') {
      links.scrollTo({ left: 194.27 + 257.56 + 129.25 + 154.9 });
    }
  }, [location.pathname, isInDesktopBreakpointRange, isNewProfileNavigationEnabled]);

  const handleLinkClick = (left: number) => () => {
    linksRef.current!.scrollTo({ behavior: 'smooth', left });
  };

  const profileLinks =
    isNewProfileNavigationEnabled === true
      ? [
          {
            link: '/dashboard/profile/account-information',
            title: 'Account information'
          },
          {
            children: [
              {
                link: '/identity-and-languages',
                title: 'Identity & languages'
              },
              {
                link: '/cultural-competencies',
                title: 'Community engagement'
              },
              {
                link: '/work-experience',
                title: 'Work experience'
              },
              {
                link: '/education-history',
                title: 'Education history'
              },
              {
                link: '/additional-experience',
                title: 'Additional experience'
              }
            ],
            hideCondition: !user.is_clinical,
            link: '/dashboard/profile/identity-and-languages',
            parentLink: '/dashboard/profile',
            title: 'Background'
          },
          {
            link: '/dashboard/my-education/completions-and-certificates',
            title: 'My education'
          }
        ]
      : [
          {
            link: '/dashboard/profile/information',
            onClick: isInMobileBreakpointRange ? handleLinkClick(0) : undefined,
            title: 'Your information'
          },
          {
            hideCondition: !user.is_clinical,
            link: '/dashboard/profile/cultural-competencies',
            onClick: isInMobileBreakpointRange ? handleLinkClick(194.27) : undefined,
            title: 'Cultural competencies'
          },
          {
            link: '/dashboard/profile/certifications',
            onClick: isInMobileBreakpointRange ? handleLinkClick(194.27 + 257.56) : undefined,
            title: 'Education'
          },
          {
            hideCondition: !user.is_clinical,
            link: '/dashboard/profile/work-experience',
            onClick: isInMobileBreakpointRange
              ? handleLinkClick(194.27 + 257.56 + 129.25)
              : undefined,
            title: 'Work experience'
          },
          {
            hideCondition: !user.is_clinical,
            link: '/dashboard/profile/additional-experience',
            onClick: isInMobileBreakpointRange
              ? handleLinkClick(194.27 + 257.56 + 129.25 + 154.9)
              : undefined,
            title: 'Additional experience'
          }
        ];

  return (
    <ToastProvider>
      {toastState => (
        <>
          <SecondaryNavigation
            linkList={profileLinks}
            linksRef={linksRef}
            navTitle="Profile"
          />
          <S.Main
            $noBottomPadding={location.pathname === '/dashboard/profile/cultural-competencies'}
          >
            <Outlet context={toastState} />
          </S.Main>
        </>
      )}
    </ToastProvider>
  );
};

export default Wrapper;
