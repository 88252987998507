import UnstyledButton from 'src/components/UnstyledButton';
import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

const METADATA_XXS_BREAKPOINT_RANGE = `@media (width <= 840px)`;
const METADATA_XS_BREAKPOINT_RANGE = `@media (width > 840px)`;
const METADATA_ITEM_STYLES = `
  display: grid;
  grid-template-columns: 1.75rem 1fr;
  grid-template-rows: 1.25rem 1.25rem;
  gap: 0 0.5rem;

  ${METADATA_XXS_BREAKPOINT_RANGE} {
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--gray-200);
    width: 100%;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  ${METADATA_XS_BREAKPOINT_RANGE} {
    border-right: 1px solid var(--gray-200);
    padding: 0 1rem;
    flex-grow: 1;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
    }
  }

  svg {
    fill: var(--gray-600);
    width: 1.75rem;
    grid-area: 1 / 1 / 3 / 2;
    height: 2.5rem;
  }
`;

export const ButtonMetaDataItem = styled(UnstyledButton)`
  ${METADATA_ITEM_STYLES}
`;
export const FaqLink = styled.a`
  color: var(--gray-dark-light);
  text-decoration: underline;
`;

export const MetaData = styled.div`
  ${TEXT_STYLES.bodyCopyMedium}
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  border: 1px solid var(--gray-200);
  background: #fff;
  color: var(--gray-500);
  width: 100%;
  overflow: auto hidden;
  margin-bottom: 1rem;

  ${METADATA_XXS_BREAKPOINT_RANGE} {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  ${METADATA_XS_BREAKPOINT_RANGE} {
    display: inline-flex;
  }
`;

export const MetaDataItem = styled.div`
  ${METADATA_ITEM_STYLES}
`;

export const MetaDataItemHeader = styled.div`
  grid-area: 1 / 2 / 2 / 3;
`;
export const MetaDataItemValue = styled.div`
  grid-area: 2 / 2 / 3 / 3;
  font-weight: 500;
`;

export const PopoverAccreditingOrganizationItem = styled.li`
  ${TEXT_STYLES.tooltipText}

  strong {
    font-weight: bold;
  }
`;

export const PopoverAccreditingOrganizationList = styled.ul`
  margin-left: 1rem;
  padding-left: 0;
`;

export const PopoverContent = styled.div`
  ${TEXT_STYLES.tooltipText}
  color: var(--color-base-white);
  max-width: 18.75rem;
`;

export const PopoverTitle = styled.div`
  ${TEXT_STYLES.tooltipTitle}
  color: var(--color-base-white);
`;
