import { useEffect, useState } from 'react';
import CollectionCard from 'src/components/Education/CollectionCard';
import { ResourceList } from 'src/components/Education/EducationCarousel';
import PathwayCard from 'src/components/Education/PathwayCard';
import PageTitle from 'src/components/PageTitle';
import Spinner from 'src/components/Spinner';
import { Collection, Pathway } from 'src/pages/Dashboard/utils';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import useGetCollections from '../../hooks/useGetCollections';
import useGetPathways from '../../hooks/useGetPathways';

import * as S from './styles';

const InProgressEducation = () => {
  const [inProgressResources, setInProgressResources] = useState<ResourceList | null>(null);
  /*
    TODO:
    Replace with a single API call to get all in-progress resources when it exists
    that can also be paginated
  */
  const { collections: inProgressCollections, isFetching: isFetchingCollections } =
    useGetCollections({
      page: 1,
      per_page: 100,
      status: ['in_progress']
    });
  const { isFetching: isFetchingPathways, pathways: inProgressPathways } = useGetPathways({
    page: 1,
    per_page: 100,
    'status[]': ['in_progress']
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isFetchingPathways || isFetchingCollections) return;
    const combinedInProgressResources = (
      [
        ...inProgressPathways.map(pathway => ({ ...pathway, resourceType: 'Pathway' })),
        ...inProgressCollections.map(collection => ({
          ...collection,
          resourceType: 'CourseCollection'
        }))
      ] as ResourceList
    ).sort((a, b) => {
      // Most recent startedAt to oldest
      const aStartedAt =
        'startedAt' in a ? a.startedAt : 'completion' in a ? a.completion?.startedAt : '';
      const bStartedAt =
        'startedAt' in b ? b.startedAt : 'completion' in b ? (b.completion?.startedAt ?? '') : '';

      const dateA = isNonEmptyString(aStartedAt) ? new Date(aStartedAt!) : new Date();
      const dateB = isNonEmptyString(bStartedAt) ? new Date(bStartedAt!) : new Date();

      return dateB.getTime() - dateA.getTime(); // Most recent first
    });
    setInProgressResources(combinedInProgressResources);
  }, [isFetchingCollections, isFetchingPathways, inProgressPathways, inProgressCollections]);

  useEffect(() => {
    if (inProgressResources === null) return;
    setIsLoading(false);
  }, [inProgressResources]);

  return (
    <S.FullWidthMain>
      <PageTitle
        title="In-progress"
        titleVariant="h1"
      />
      {isLoading || inProgressResources === null ? (
        <Spinner withWrapper />
      ) : inProgressResources.length > 0 ? (
        <S.InProgressResources data-cy="in-progress-resources">
          {inProgressResources.map(resource => {
            const { resourceType, ...resourceData } = resource;
            if (resourceType === 'Pathway') {
              return (
                <PathwayCard
                  key={resource.id}
                  pathway={resourceData as Pathway}
                />
              );
            } else if (resourceType === 'CourseCollection') {
              return (
                <CollectionCard
                  key={resource.id}
                  collection={resourceData as Collection}
                />
              );
            } else {
              return null;
              /*
                TODO: Include CourseCard when we have a filter to pull in-progress courses
                  Will also need to support viewing a course on this page
              */
              // return (<CourseCard key={resource.id} course={resourceData as EducationalResource} />)
            }
          })}
        </S.InProgressResources>
      ) : (
        <S.NoInProgressResources
          data-cy="no-in-progress-resources-alert"
          header="No education in-progress."
          id="no-in-progress-resources"
          isBlock
          isDismissable={false}
          type="info"
        >
          It looks like you haven’t started any courses yet. Not sure where to begin?
          <br />
          <br />
          Explore our learning pathways or browse individual collections to find topics that
          interest you. Use filters like accrediting organization, community, and specialty to help
          guide your search.
        </S.NoInProgressResources>
      )}
    </S.FullWidthMain>
  );
};

export default InProgressEducation;
