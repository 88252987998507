import { ToastState } from '@react-stately/toast';
import * as Sentry from '@sentry/react';
import { produce } from 'immer';
import { FormEventHandler, Key, useEffect, useRef, useState } from 'react';
import { AriaCheckboxGroupProps, AriaComboBoxProps, useFilter } from 'react-aria';
import { useOutletContext } from 'react-router-dom';
import { Item } from 'react-stately';
import ComboBox from 'src/components/FormFields/ComboBox';
import { Props as LanguageSearchProps } from 'src/components/FormFields/LanguageSearch';
import TextField from 'src/components/FormFields/TextField';
import PageTitle from 'src/components/PageTitle';
import { VioletToast } from 'src/components/ToastProvider';
import { STATES } from 'src/pages/constants';
import { LANGUAGE_SEARCH_TEXT } from 'src/pages/strings';
import LanguageProficienciesData from 'src/utils/data/LanguageProficiencies';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import Button from '../../../../components/Buttons/Button';
import CheckboxGroup from '../../../../components/FormFields/CheckboxGroup';
import GroupCheckbox from '../../../../components/FormFields/CheckboxGroup/GroupCheckbox';
import useBreakpointRange from '../../../../hooks/useBreakpointRange';
import useOpenErrorModalDialog from '../../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../../../hooks/useUser';

import PhoneNumberField from './PhoneNumberField';
import * as S from './styles';

const allPronouns = ['She/Her/Hers', 'He/Him/His', 'They/Them/Theirs', 'Prefer not to say'];

const allGenderIdentities = [
  'Cisgender woman',
  'Cisgender man',
  'Transgender woman',
  'Transgender man',
  'Non-binary',
  'Genderqueer',
  'Agender',
  'Intersex',
  'Prefer not to say'
] as const;

const allRaceEthnicities = [
  'Native American or Alaskan Native',
  'Asian',
  'Black or African American',
  'Hispanic or Latine/x',
  'Native Hawaiian or Pacific Islander',
  'White',
  'Middle Eastern or North African',
  'Prefer not to say'
] as const;

const allSexualOrientations = [
  'Heterosexual',
  'Gay',
  'Lesbian',
  'Bisexual',
  'Pansexual',
  'Queer',
  'Asexual',
  'Prefer not to say'
] as const;

const Information = () => {
  const { isInDesktopBreakpointRange } = useBreakpointRange();
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const { bearerToken, setUser, user } = useUser();
  const toastState = useOutletContext();

  const formFieldRefs = {
    city: useRef<HTMLInputElement>(null),
    firstName: useRef<HTMLInputElement>(null),
    lastName: useRef<HTMLInputElement>(null),
    npi: useRef<HTMLInputElement>(null),
    phoneNumber: useRef<HTMLInputElement>(null)
  };
  const [pronounSets, setPronounSets] = useState<string[]>(user.user_info.pronouns);
  const [raceEthnicities, setRaceEthnicities] = useState<string[]>(user.user_info.race_ethnicity);
  const [sexualOrientations, setSexualOrientations] = useState<string[]>(
    user.user_info.sexual_orientation
  );
  const [genderIdentities, setGenderIdentities] = useState<string[]>(
    user.user_info.gender_identity
  );
  const [selectedLanguages, setSelectedLanguages] = useState<
    { id?: string; language: Key | null; proficiency: Key }[]
  >([]);
  const [state, setState] = useState<Key | null | undefined>(user.state ?? undefined);

  const { contains } = useFilter({ sensitivity: 'base' });

  const languageProficienciesDataRef = useRef(new LanguageProficienciesData(bearerToken));

  const functionToCallAfterUserUpdateRef = useRef<() => void>();

  useEffect(() => {
    if (functionToCallAfterUserUpdateRef.current) {
      const functionToCallAfterUserUpdate = functionToCallAfterUserUpdateRef.current;
      functionToCallAfterUserUpdateRef.current = undefined;
      functionToCallAfterUserUpdate();
    }
  }, [user]);

  useEffect(() => {
    languageProficienciesDataRef.current.findAll().then(({ json, response }) => {
      if (!response.ok) {
        openErrorModalDialog();
      } else {
        setSelectedLanguages(json.data);
      }
    });
  }, [openErrorModalDialog]);

  const [formIsSubmitting, setFormIsSubmitting] = useState(false);

  const handleFormSubmit: FormEventHandler = async event => {
    event.preventDefault();

    setFormIsSubmitting(true);

    const url: RequestInfo = `${process.env.REACT_APP_API_BASE_PATH}/users/dashboard/general_info`;

    const firstName = formFieldRefs.firstName.current!.value;
    const lastName = formFieldRefs.lastName.current!.value;
    const phoneNumber = formFieldRefs.phoneNumber.current!.value;
    const npi = isNonEmptyString(formFieldRefs.npi.current?.value)
      ? formFieldRefs.npi.current?.value
      : null;
    const city = formFieldRefs.city.current?.value ?? null;

    const options: RequestInit = {
      body: JSON.stringify({
        user: {
          city,
          first_name: firstName,
          id: user.id,
          last_name: lastName,
          state,
          user_info: {
            gender_identity: genderIdentities,
            id: user.user_info.id,
            npi,
            phone_number: phoneNumber.replace(/[^0-9]/g, ''),
            pronouns: pronounSets,
            race_ethnicity: raceEthnicities,
            sexual_orientation: sexualOrientations,
            user_id: user.id
          }
        }
      }),
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PATCH'
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else if (response.status === 422) {
          const error = (await response.json()) as { ['user_info.npi']: string[] };
          if (error['user_info.npi'].includes('has already been taken')) {
            throw new Error('npi_error_taken');
          } else if (
            error['user_info.npi'].includes('is the wrong length (should be 10 characters)')
          ) {
            throw new Error('npi_error_length');
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { data } = (await response.json()) as APIUsersDashboardGeneralInfo;

      const languageUpdateSuccess =
        await languageProficienciesDataRef.current.updateAll(selectedLanguages);

      if (!languageUpdateSuccess) {
        throw new Error('There was an issue saving languages.');
      }

      setUser(
        produce(user, draft => {
          draft.first_name = data.first_name;
          draft.last_name = data.last_name;
          draft.user_info.npi = data.user_info.npi;
          draft.user_info.gender_identity = data.user_info.gender_identity;
          draft.user_info.languages = data.user_info.languages;
          draft.user_info.phone_number = data.user_info.phone_number;
          draft.user_info.pronouns = data.user_info.pronouns;
          draft.user_info.race_ethnicity = data.user_info.race_ethnicity;
          draft.user_info.sexual_orientation = data.user_info.sexual_orientation;
          draft.city = data.city;
          draft.state = data.state;
        })
      );

      functionToCallAfterUserUpdateRef.current = () => {
        window.scrollTo(0, 0);
        (toastState as ToastState<VioletToast>).add(
          {
            description: 'Your data has been saved.',
            type: 'success'
          },
          { timeout: 8000 }
        );
      };
    } catch (error) {
      (toastState as ToastState<VioletToast>).add(
        {
          description: 'Something went wrong. Please check for errors.',
          type: 'error'
        },
        { timeout: 8000 }
      );

      if (error instanceof Error && error.message.includes('npi_error_')) {
        if (error.message.includes('npi_error_taken')) {
          formFieldRefs.npi.current!.setCustomValidity(
            'This NPI is already taken. Check it is typed correctly, or contact support.'
          );
          formFieldRefs.npi.current!.reportValidity();
        } else if (error.message.includes('npi_error_length')) {
          formFieldRefs.npi.current!.setCustomValidity('NPI should be 10 characters long.');
          formFieldRefs.npi.current!.reportValidity();
        }
      } else {
        Sentry.captureException(error);
        openErrorModalDialog();
      }
    }

    setFormIsSubmitting(false);
  };

  const handlePronounSetsChange: AriaCheckboxGroupProps['onChange'] = pronounSets => {
    setPronounSets(pronounSets);
  };

  const handleRaceEthnicityChange: AriaCheckboxGroupProps['onChange'] = raceEthnicitySet => {
    setRaceEthnicities(raceEthnicitySet);
  };

  const handleGenderIdentitiesChange: AriaCheckboxGroupProps['onChange'] = genderIdentitySet => {
    setGenderIdentities(genderIdentitySet);
  };

  const handleSexualOrientationChange: AriaCheckboxGroupProps['onChange'] =
    sexualOrientationSet => {
      setSexualOrientations(sexualOrientationSet);
    };

  const handleLanguageSearchAddition: LanguageSearchProps['addLanguage'] = value => {
    const newSelection = selectedLanguages.concat(value);
    setSelectedLanguages(newSelection);
  };

  const handleLanguageSearchDeletion: LanguageSearchProps['deleteLanguage'] = value => {
    const newSelection = selectedLanguages.filter(item => item !== value);
    setSelectedLanguages(newSelection);
  };

  const updateStateSelection: AriaComboBoxProps<object>['onSelectionChange'] = value =>
    setState(value);

  const clearNpiErrors = () => {
    formFieldRefs.npi.current!.setCustomValidity('');
  };

  return (
    <>
      <PageTitle
        title="Personal information"
        titleVariant="h1"
      />
      <form onSubmit={handleFormSubmit}>
        <S.FormSection1>
          <TextField
            ref={formFieldRefs.firstName}
            autoComplete="given-name"
            defaultValue={user.first_name!}
            isRequired
            label="First name"
            placeholder="First name"
            validationBehavior="native"
          />
          <TextField
            ref={formFieldRefs.lastName}
            autoComplete="family-name"
            defaultValue={user.last_name!}
            isRequired
            label="Last name"
            placeholder="Last name"
            validationBehavior="native"
          />
          <PhoneNumberField
            ref={formFieldRefs.phoneNumber}
            defaultValue={user.user_info.phone_number ?? ''}
          />
        </S.FormSection1>
        {user.is_clinical && (
          <S.FormSection1>
            <TextField
              ref={formFieldRefs.npi}
              data-cy="npi-field"
              defaultValue={user.user_info.npi ?? ''}
              errorMessage={formFieldRefs.npi.current?.validationMessage}
              isInvalid={formFieldRefs.npi.current?.validity.valid === false}
              label="NPI (National Provider ID)"
              onChange={clearNpiErrors}
              placeholder="NPI"
            />
            <TextField
              ref={formFieldRefs.city}
              autoComplete="address-level2"
              data-cy="city-field"
              defaultValue={user.city!}
              label="City"
            />
            <ComboBox
              data-cy="state-field"
              filter={contains}
              label="State"
              onSelectionChange={updateStateSelection}
              // Remove when this is resolved: https://github.com/adobe/react-spectrum/issues/5492
              //@ts-expect-error
              selectedKey={state}
            >
              {STATES.map(({ abbreviation, name }) => (
                <Item
                  key={abbreviation}
                  textValue={`${abbreviation} (${name})`}
                >
                  {abbreviation} ({name})
                </Item>
              ))}
            </ComboBox>
          </S.FormSection1>
        )}
        <S.Label>Pronouns*</S.Label>
        <CheckboxGroup
          allowOther
          aria-label="Pronouns*"
          data-cy="pronoun-sets-field"
          direction={isInDesktopBreakpointRange ? 'horizontal' : 'vertical'}
          groupLabelColor="gray-800"
          isRequired
          onChange={handlePronounSetsChange}
          otherLabel="Or enter your pronouns"
          size="regular"
          validationBehavior="native"
          value={pronounSets}
        >
          {allPronouns.map(pronouns => (
            <GroupCheckbox
              key={pronouns}
              name="selectedPronouns"
              value={pronouns}
            >
              {pronouns}
            </GroupCheckbox>
          ))}
        </CheckboxGroup>
        <S.FormSection2>
          <S.LanguageSearchSection
            addLanguage={handleLanguageSearchAddition}
            copy={
              user.is_clinical
                ? LANGUAGE_SEARCH_TEXT.copyClinical
                : LANGUAGE_SEARCH_TEXT.copyNonClinical
            }
            data-hj-allow
            deleteLanguage={handleLanguageSearchDeletion}
            header={
              user.is_clinical
                ? LANGUAGE_SEARCH_TEXT.titleClinical
                : LANGUAGE_SEARCH_TEXT.titleNonClinical
            }
            selectedLanguages={selectedLanguages}
          />
        </S.FormSection2>
        <S.FormSection3>
          <CheckboxGroup
            data-cy="race-ethnicity-field"
            direction="vertical"
            groupLabelColor="gray-800"
            isRequired
            label="Race/Ethnicity*"
            onChange={handleRaceEthnicityChange}
            size="regular"
            validationBehavior="native"
            value={raceEthnicities}
          >
            {allRaceEthnicities.map(raceEthnicity => (
              <GroupCheckbox
                key={raceEthnicity}
                name="selectedRaceEthnicities"
                value={raceEthnicity}
              >
                {raceEthnicity}
              </GroupCheckbox>
            ))}
          </CheckboxGroup>
          <CheckboxGroup
            data-cy="gender-identity-field"
            direction="vertical"
            groupLabelColor="gray-800"
            isRequired
            label="Gender identity*"
            onChange={handleGenderIdentitiesChange}
            size="regular"
            validationBehavior="native"
            value={genderIdentities}
          >
            {allGenderIdentities.map(genderIdentity => (
              <GroupCheckbox
                key={genderIdentity}
                name="selectedGenderIdentities"
                value={genderIdentity}
              >
                {genderIdentity}
              </GroupCheckbox>
            ))}
          </CheckboxGroup>
          <CheckboxGroup
            data-cy="sexual-orientation-field"
            direction="vertical"
            groupLabelColor="gray-800"
            isRequired
            label="Sexual orientation*"
            onChange={handleSexualOrientationChange}
            size="regular"
            validationBehavior="native"
            value={sexualOrientations}
          >
            {allSexualOrientations.map(sexualOrientation => (
              <GroupCheckbox
                key={sexualOrientation}
                name="selectedSexualOrientations"
                value={sexualOrientation}
              >
                {sexualOrientation}
              </GroupCheckbox>
            ))}
          </CheckboxGroup>
        </S.FormSection3>
        <S.Buttons>
          <Button
            data-cy="save-button"
            isLoading={formIsSubmitting}
            size="large"
            type="submit"
          >
            Save
          </Button>
        </S.Buttons>
      </form>
    </>
  );
};

export default Information;
