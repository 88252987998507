import { ReactComponent as MenuIcon } from '@material-design-icons/svg/round/menu.svg';
import { ReactComponent as MenuOpenIcon } from '@material-design-icons/svg/round/menu_open.svg';
import { Link } from 'react-router-dom';
import { default as LogotypePlain } from 'src/components/Logotype';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../constants';
import UnstyledButton from '../UnstyledButton';

const sidebarWidth = 94;

interface HeaderProps {
  $menuIsOpen: boolean;
}

export const Header = styled.header<HeaderProps>`
  background-color: var(--purple-400);

  ${MOBILE_BREAKPOINT_RANGE} {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    padding-top: 0.4375rem;
    padding-bottom: 0.625rem;
    height: 4.0625rem;
    z-index: 2;

    ${({ $menuIsOpen }) =>
      $menuIsOpen &&
      `
      width: 100vw;
    `}
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    position: fixed;
    left: 0;
    width: ${sidebarWidth}px;
    height: 100%;
    padding-top: 2.5rem;
    z-index: 99;
  }
`;

export const Layout = styled.div`
  min-height: 100vh;
  background: #f8f8f8;

  ${DESKTOP_BREAKPOINT_RANGE} {
    padding-left: ${sidebarWidth}px;
  }
`;

export const Logotype = {
  Image: styled(LogotypePlain)`
    ${MOBILE_BREAKPOINT_RANGE} {
      height: 2.1875rem;
      width: 7.1875rem;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      height: 1.35rem;
      width: 4.375rem;
    }
  `,
  Root: styled(Link)`
    ${MOBILE_BREAKPOINT_RANGE} {
      display: grid;
      align-items: center;
      margin-right: auto;
      margin-left: auto;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      display: grid;
      place-content: center;
      margin-bottom: 3rem;
      height: 1.5rem;
    }
  `
};

interface MenuButtonRootProps {
  $menuIsOpen: boolean;
}

// Note: this is for mobile
export const MenuButton = {
  MenuIcon: styled(MenuIcon)`
    display: block;
    transition: opacity 0.8s;
    fill: var(--white-light);
    height: 2rem;
    width: auto;
  `,
  MenuOpenIcon: styled(MenuOpenIcon)`
    position: absolute;
    inset: 0 -0.25rem 0 0;
    margin: auto;
    transition: opacity 0.8s;
    fill: var(--white-light);
    height: 2rem;
    width: auto;
  `,
  Root: styled.button<MenuButtonRootProps>`
    all: unset;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    padding: 1.5625rem 2.5625rem;
    cursor: pointer;

    &.is-focused {
      outline: revert;
    }

    ${({ $menuIsOpen }) => {
      const { styledComponentId: menuIconClassName } = MenuButton.MenuIcon as {
        styledComponentId: string;
      };
      const { styledComponentId: menuOpenIconClassName } = MenuButton.MenuOpenIcon as {
        styledComponentId: string;
      };

      if ($menuIsOpen) {
        return `
          .${menuIconClassName} {
            opacity: 0;
          }

          .${menuOpenIconClassName} {
            opacity: 1;
          }
        `;
      } else {
        return `
          .${menuIconClassName} {
            opacity: 1;
          }

          .${menuOpenIconClassName} {
            opacity: 0;
          }
        `;
      }
    }}
  `
};

export const Navigation = {
  Link: styled(UnstyledButton)`
    display: grid;
    place-content: center;
    width: 3rem;
    height: 3rem;
    outline: none;

    ${DESKTOP_BREAKPOINT_RANGE} {
      & > svg {
        width: 3rem;
        height: 3rem;
      }
    }

    & > svg > path,
    & > svg > rect {
      fill: var(--white-light);
      opacity: 0.5;
      transition:
        fill 0.3s,
        opacity 0.3s;
    }

    &.is-selected,
    &:hover,
    &:focus {
      & > svg > path,
      & > svg > rect {
        fill: var(--white-light);
        opacity: 1;
      }
    }
  `,
  Root: styled.nav`
    display: grid;
    place-items: center;
    gap: 1.25rem;
  `
};

export const TooltipText = styled.div`
  ${TEXT_STYLES.tooltipText}
  white-space: nowrap;
`;
